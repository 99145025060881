import React, { forwardRef } from 'react';

import AkButton, {
	type ButtonProps as AkButtonProps,
	IconButton as AkIconButton,
	type IconButtonProps as AkIconButtonProps,
} from '@atlaskit/button/new';

import {
	ThemedButton,
	type ThemedButtonProps,
	ThemedIconButton,
	type ThemedIconButtonProps,
} from './button';
import { useHasCustomTheme } from './has-custom-theme-context';

// Needs explicit types, otherwise the inferred types reach into `/dist` and cause lint errors.
const IconButton: React.ForwardRefExoticComponent<
	ThemedIconButtonProps & AkIconButtonProps & React.RefAttributes<HTMLButtonElement>
> = forwardRef<HTMLButtonElement, ThemedIconButtonProps & AkIconButtonProps>((props, ref) => {
	const hasCustomTheme = useHasCustomTheme();
	const Component = hasCustomTheme ? ThemedIconButton : AkIconButton;

	return <Component ref={ref} {...props} />;
});

// Needs explicit types, otherwise the inferred types reach into `/dist` and cause lint errors.
const Button: React.ForwardRefExoticComponent<
	ThemedButtonProps & AkButtonProps & React.RefAttributes<HTMLButtonElement>
> = forwardRef<HTMLButtonElement, ThemedButtonProps & AkButtonProps>((props, ref) => {
	const hasCustomTheme = useHasCustomTheme();
	const Component = hasCustomTheme ? ThemedButton : AkButton;

	return <Component ref={ref} {...props} />;
});

export { IconButton, Button };
