/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef } from 'react';

import { cssMap, cx, jsx } from '@compiled/react';

import type { IconButtonProps } from '@atlaskit/button/new';
import Pressable, { type PressableProps } from '@atlaskit/primitives/pressable';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import VisuallyHidden from '@atlaskit/visually-hidden';

export const themedButtonBackground = '--ds-top-bar-button-background';
export const themedButtonBackgroundHovered = '--ds-top-bar-button-background-hovered';
export const themedButtonBackgroundPressed = '--ds-top-bar-button-background-pressed';
export const themedButtonBorder = '--ds-top-bar-button-border';

export const themedButtonPrimaryText = '--ds-top-bar-button-primary-text';
export const themedButtonPrimaryBackground = '--ds-top-bar-button-primary-background';
export const themedButtonPrimaryBackgroundHovered =
	'--ds-top-bar-button-primary-background-hovered';
export const themedButtonPrimaryBackgroundPressed =
	'--ds-top-bar-button-primary-background-pressed';

export const themedButtonSelectedText = '--ds-top-bar-button-selected-text';
export const themedButtonSelectedBackground = '--ds-top-bar-button-selected-background';
export const themedButtonSelectedBackgroundHovered =
	'--ds-top-bar-button-selected-background-hovered';
export const themedButtonSelectedBackgroundPressed =
	'--ds-top-bar-button-selected-background-pressed';

interface SharedButtonProps {
	appearance?: 'default' | 'subtle' | 'primary';
	onClick: PressableProps['onClick'];
	isSelected?: boolean;
	interactionName?: string;
	testId?: string;
}

/**
 * Ensures we are providing all props to the base component,
 * while still allowing `undefined` values.
 */
type RequiredKeepUndefined<T> = {
	[K in keyof Required<T>]: T[K];
};

type BaseThemedButtonProps = RequiredKeepUndefined<SharedButtonProps> & {
	type: 'Button' | 'IconButton';
	iconBefore?: IconButtonProps['icon'];
	children: React.ReactNode;
};

export interface ThemedButtonProps extends SharedButtonProps {
	iconBefore?: IconButtonProps['icon'];
	children: React.ReactNode;
}

export interface ThemedIconButtonProps extends SharedButtonProps {
	icon: IconButtonProps['icon'];
	label: React.ReactNode;
	// TODO: support
	tooltip?: IconButtonProps['tooltip'];
}

// TODO: use codegen to ensure these don't get stale
const styles = cssMap({
	root: {
		display: 'flex',
		gap: token('space.050'),
		alignItems: 'center',
		justifyContent: 'center',
		font: token('font.body'),
		height: '2.2857142857142856em',
		paddingBlock: token('space.0'),
		borderRadius: token('border.radius.100', '3px'),
		transition: 'background 0.1s ease-out',
		position: 'relative',
	},
	border: {
		'&::after': {
			content: '""',
			borderRadius: 'inherit',
			borderStyle: 'solid',
			borderWidth: token('border.width'),
			position: 'absolute',
			inset: token('space.0'),
		},
	},
	selected: {
		color: `var(${themedButtonSelectedText})`,
		background: `var(${themedButtonSelectedBackground})`,
		'&:hover': {
			background: `var(${themedButtonSelectedBackgroundHovered})`,
		},
		'&:active': {
			background: `var(${themedButtonSelectedBackgroundPressed})`,
		},
		'&::after': {
			borderColor: token('color.border.selected'),
		},
	},
	hasIconBefore: {
		paddingInlineStart: token('space.100'),
	},
	iconBefore: {
		paddingInlineStart: token('space.050'),
		paddingInlineEnd: token('space.025'),
		lineHeight: 0,
	},
	text: {
		fontWeight: token('font.weight.medium'),
	},

	textButton: {
		paddingInline: token('space.150'),
	},
	iconButton: {
		width: '2.2857142857142856em',
		paddingInline: token('space.0'),
		lineHeight: 0,
	},
});

const appearanceStyles = cssMap({
	default: {
		color: 'currentColor',
		background: `var(${themedButtonBackground})`,
		'&:hover': {
			background: `var(${themedButtonBackgroundHovered})`,
		},
		'&:active': {
			background: `var(${themedButtonBackgroundPressed})`,
		},
		'&::after': {
			borderColor: `var(${themedButtonBorder})`,
		},
	},
	subtle: {
		color: 'currentColor',
		background: `var(${themedButtonBackground})`,
		'&:hover': {
			background: `var(${themedButtonBackgroundHovered})`,
		},
		'&:active': {
			background: `var(${themedButtonBackgroundPressed})`,
		},
	},
	primary: {
		color: `var(${themedButtonPrimaryText})`,
		background: `var(${themedButtonPrimaryBackground})`,
		'&:hover': {
			background: `var(${themedButtonPrimaryBackgroundHovered})`,
		},
		'&:active': {
			background: `var(${themedButtonPrimaryBackgroundPressed})`,
		},
	},
});

const BaseThemedButton = forwardRef<HTMLButtonElement, BaseThemedButtonProps>(
	function BaseThemedButton(
		{
			appearance = 'default',
			type,
			onClick,
			isSelected,
			interactionName,
			testId,
			iconBefore: IconBefore,
			children,
		},
		ref,
	) {
		const isTextButton = type === 'Button';
		const isIconButton = type === 'IconButton';

		const hasBorder = appearance === 'default' || isSelected;

		return (
			<Pressable
				ref={ref}
				// @ts-expect-error
				// eslint-disable-next-line @compiled/no-suppress-xcss
				xcss={cx(
					styles.root,
					isTextButton && styles.textButton,
					isIconButton && styles.iconButton,
					hasBorder && styles.border,
					appearanceStyles[appearance],
					isSelected && styles.selected,
					IconBefore && styles.hasIconBefore,
				)}
				onClick={onClick}
				interactionName={interactionName}
				testId={testId}
			>
				{IconBefore && (
					<span css={styles.iconBefore}>
						<IconBefore label="" color="currentColor" />
					</span>
				)}
				{isTextButton ? <span css={styles.text}>{children}</span> : children}
			</Pressable>
		);
	},
);

/**
 * __Themed button__
 *
 * A themed button for the top bar.
 */
export const ThemedButton = forwardRef<HTMLButtonElement, ThemedButtonProps>(function ThemedButton(
	{ appearance = 'default', iconBefore, children, onClick, isSelected, interactionName, testId },
	ref,
) {
	return (
		<BaseThemedButton
			type="Button"
			ref={ref}
			appearance={appearance}
			onClick={onClick}
			interactionName={interactionName}
			testId={testId}
			iconBefore={iconBefore}
			isSelected={isSelected}
		>
			{children}
		</BaseThemedButton>
	);
});

/**
 * __Themed icon button__
 *
 * A themed icon button for the top bar.
 */
export const ThemedIconButton = forwardRef<HTMLButtonElement, ThemedIconButtonProps>(
	function ThemedIconButton(
		{ appearance = 'default', icon: Icon, label, onClick, isSelected, interactionName, testId },
		ref,
	) {
		return (
			// TODO: look at using render props API
			// or introduce an API that only uses ref
			<Tooltip content={label}>
				<BaseThemedButton
					ref={ref}
					onClick={onClick}
					type="IconButton"
					appearance={appearance}
					interactionName={interactionName}
					testId={testId}
					isSelected={isSelected}
				>
					<Icon label="" color="currentColor" />
					<VisuallyHidden>{label}</VisuallyHidden>
				</BaseThemedButton>
			</Tooltip>
		);
	},
);
