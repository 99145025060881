/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import type { LogoProps } from '@atlaskit/logo';
import { Anchor } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { useHasCustomTheme } from './themed/has-custom-theme-context';

export const themedLogoIcon = '--ds-top-bar-logo-icon';
export const themedLogoText = '--ds-top-bar-logo-text';

const navLogoStyles = cssMap({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '32px',
		borderRadius: token('border.radius.100'),
	},
	icon: {
		display: 'flex',
		paddingInline: token('space.050'),
		'@media (min-width: 64rem)': {
			// '&&' is required to add more CSS specificity to resolve non-deterministic ordering, which can result in
			// both the `icon` and `logo` elements to be displayed at the same time
			// Clean up task: https://jplat.atlassian.net/browse/BLU-4788
			// @ts-ignore
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&&': {
				display: 'none',
			},
		},
	},
	logo: {
		display: 'none',
		paddingInline: token('space.100'),
		'@media (min-width: 64rem)': {
			// '&&' is required to add more CSS specificity to resolve non-deterministic ordering, which can result in
			// both the `icon` and `logo` elements to be displayed at the same time
			// Clean up task: https://jplat.atlassian.net/browse/BLU-4788
			// @ts-ignore
			// eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&&': {
				display: 'flex',
			},
		},
	},
});

/**
 * __Nav logo__
 *
 * The product logo for the top navigation.
 */
export const NavLogo = ({
	href,
	logo,
	icon,
	onClick,
	label = 'Home',
}: {
	label?: string;
	href: string;
	logo: (props: LogoProps) => JSX.Element;
	icon: (props: LogoProps) => JSX.Element;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}) => (
	<Anchor aria-label={label} href={href} xcss={navLogoStyles.root} onClick={onClick}>
		<div css={navLogoStyles.icon}>
			<NavLogoRenderer logoOrIcon={icon} />
		</div>
		<div css={navLogoStyles.logo}>
			<NavLogoRenderer logoOrIcon={logo} />
		</div>
	</Anchor>
);

function NavLogoRenderer({
	logoOrIcon: LogoOrIcon,
}: {
	logoOrIcon: (props: LogoProps) => JSX.Element;
}) {
	const hasCustomTheme = useHasCustomTheme();

	if (hasCustomTheme) {
		return (
			<LogoOrIcon
				size="small"
				label=""
				iconColor={`var(${themedLogoIcon})`}
				textColor={`var(${themedLogoText})`}
			/>
		);
	}

	return <LogoOrIcon size="small" label="" appearance="brand" />;
}
